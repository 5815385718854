<template>
  <span v-if="value">
    <b-icon-geo-alt v-if="displayIcon"></b-icon-geo-alt>
    <b-link
      :href="`https://maps.google.com/?q=${urlEncoded}`"
      target="_blank"
      @click.stop
    >
      {{ value }}
    </b-link>
  </span>
</template>

<script>
export default {
  name: "AddressComponent",
  props: ["value", "icon"],
  computed: {
    displayIcon() {
      return this.icon === true || this.icon === "true";
    },
    urlEncoded() {
      return encodeURI(this.value);
    },
  },
};
</script>

<style scoped></style>
