<template>
  <b-container fluid class="mt-3">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-img
              center
              class="banniere mb-3"
              :src="couvertureSrc"
              :alt="couvertureAlt"
            ></b-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h1>{{ item.attributes.titre }}</h1>
            <p>
              <span class="d-inline-block mr-3">
                <b-icon-calendar></b-icon-calendar>
                {{ dateHeure }}
              </span>
              <span class="d-inline-block mr-3">
                <AddressComponent
                  :value="item.attributes.adresse"
                  :icon="true"
                ></AddressComponent>
              </span>
              <b-link
                target="_blank"
                :href="icsHref"
                :download="`evenement-${item.id}.ics`"
                @click.stop
              >
                <b-button pill variant="primary" @click="downloadIcs">
                  <b-icon-download></b-icon-download>
                  &nbsp;Ajouter l'&eacute;v&egrave;nement &agrave; mon agenda
                </b-button>
              </b-link>
            </p>
            <p>
              <em>{{ item.attributes.description }}</em>
            </p>
            <StrapiMarkdownComponent :item="item.attributes.contenu">
            </StrapiMarkdownComponent>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="auto" class="mt-3 mt-md-0">
        <StrapiFichiersComponent :item="item.attributes.fichiers">
        </StrapiFichiersComponent>
        <b-container v-if="aVenir.length > 0" class="dernieres-publications">
          <h2>Prochains &eacute;v&egrave;nements</h2>
          <ul>
            <li v-for="autre in aVenir" :key="autre.id">
              <b-link :to="{ name: 'evenement', params: { id: autre.id } }">
                {{ autre.attributes.titre }}
              </b-link>
            </li>
          </ul>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <StrapiCarrouselComponent :item="item.attributes.gallerie">
        </StrapiCarrouselComponent>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AddressComponent from "../AddressComponent.vue";
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
import StrapiCarrouselComponent from "./StrapiCarrouselComponent.vue";
import StrapiFichiersComponent from "./StrapiFichiersComponent.vue";
import { evenement } from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StrapiEvenementComponent",
  props: ["item", "items"],
  components: {
    AddressComponent,
    StrapiMarkdownComponent,
    StrapiCarrouselComponent,
    StrapiFichiersComponent,
  },
  computed: {
    ...evenement.computed,
    dateHeureDebut() {
      let date = this.item.attributes.dateDebut.replace(/[^\d]/g, "");
      let time =
        this.item.attributes.heureDebut?.replace(/[^\d]/g, "") ?? "000000";
      return `${date}T${time}`;
    },
    dateHeureFin() {
      let date = this.item.attributes.dateFin?.replace(/[^\d]/g, "");
      if (!date) {
        return null;
      }
      let time =
        this.item.attributes.heureFin?.replace(/[^\d]/g, "") ?? "000000";
      return `${date}T${time}`;
    },
    icsHref() {
      let ics = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${this.dateHeureDebut}
DTEND:${this.dateHeureFin ?? this.dateHeureDebut}
SUMMARY:${this.item.attributes.titre}
LOCATION:${this.item.attributes.adresse}
DESCRIPTION:${this.item.attributes.description}
END:VEVENT
END:VCALENDAR`;
      return `data:text/calendar;charset=utf8,${encodeURIComponent(ics)}`;
    },
    today() {
      return UtilsService.dateToIsoSqlDate(new Date());
    },
    aVenir() {
      return this.items
        ?.filter(
          (i) => (i.attributes.dateFin ?? i.attributes.dateDebut) >= this.today
        )
        .sort(
          UtilsService.sortBy(
            UtilsService.sortByStringProperty("attributes.dateDebut"),
            UtilsService.sortByStringProperty("attributes.dateFin")
          )
        );
    },
  },
};
</script>
